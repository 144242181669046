//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { getPricingPriceList } from '~/utils/helpers'

export default {
  data() {
    return {
      isYear: false,
      tab: 'monthly',
      tabs: [
        {
          text: 'Monthly',
          value: 'monthly'
        },
        {
          text: 'Semi Annually',
          value: 'semiAnnually'
        },
        {
          text: 'Annually',
          value: 'annually'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('subscription', [
      'getSubscriptionOrders',
      'getChangePlanConfirmShow',
      'getDetailSubscription'
    ]),
    isNextInvoicePaid() {
      if (
        !this.$auth.user.detail.current_business_subscription
          ?.latest_paid_subscription_order
      ) {
        return false
      }
      const today = new Date()
      const latestSubscriptionOrderPeriodStart = new Date(
        this.$auth.user.detail.current_business_subscription.latest_paid_subscription_order.period_start
      )
      return today.getTime() < latestSubscriptionOrderPeriodStart.getTime()
      // return true
    },
    priceList() {
      return getPricingPriceList(this.tab)
    },
    currentPlan() {
      return (
        this.$auth.user.detail.current_business_subscription
          ?.current_pricing_plan || null
      )
    },
    isChangePlanConfirmModalShow: {
      get() {
        return this.getChangePlanConfirmShow
      },
      set(val) {
        this.$store.dispatch('subscription/setChangePlanConfirmShow', val)
      }
    },
    hasInvoiceOpen() {
      return this.getSubscriptionOrders.find(
        (listSub) => listSub.status === 'open'
      )
    },
    lastInvoiceUrl() {
      if (this.getSubscriptionOrders.length === 0) {
        return null
      }
      const subscriptionOrder = this.getSubscriptionOrders[0]
      return subscriptionOrder.xendit_invoice_url
    },
    isLastInvoiceOpen() {
      if (this.getSubscriptionOrders.length === 0) {
        return false
      }
      return this.getSubscriptionOrders[0].status === 'open'
    },
    lastInvoicePricingPlan() {
      if (this.getSubscriptionOrders.length === 0) {
        return null
      }
      return this.getSubscriptionOrders[0].pricing_plan
    }
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
    showModalConfirm(nextPlan) {
      this.$emit('show-confirm', nextPlan)
    }
  }
}
