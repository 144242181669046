import { mapGettersHelper, mapMutationsHelper } from '@/utils/helpers.js'

export const state = () => ({
  kwaiPixels: [],
  kwaiEventsOnLoad: [],
  kwaiEventsOnSubmit: [],
  isAllKwaiPixelsLoaded: false,
  standardEvents: []
})

export const mutations = {
  ...mapMutationsHelper(state())
}

export const getters = {
  ...mapGettersHelper(state())
}

export const actions = {
  async fetchAll({ commit }, queries = {}) {
    const { page = 1, search = '', type = '' } = queries
    try {
      const resPixels = await this.$axios.$get(
        `/kwai-pixel/?page=${page}&name=${search}${type ? `&type=${type}` : ''}`
      )
      commit('SET_KWAI_PIXELS', resPixels.data.results)
      commit('SET_IS_ALL_KWAI_PIXELS_LOADED', resPixels.data.next === null)
      return resPixels
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchAllEvents({ commit }) {
    try {
      const resPixels = await this.$axios.$get(`/kwai-standard-event/?page_size=100`)
      commit('SET_STANDARD_EVENTS', resPixels.data.results)
      return resPixels
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async fetchById({ commit }, id) {
    try {
      const resFetchById = await this.$axios.$get(`/kwai-pixel/${id}/`)
      return resFetchById
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async storeData({ commit }, payload) {
    const { name, pixelId, conversionToken, type } = payload
    try {
      const resStoreData = await this.$axios.$post('/kwai-pixel/', {
        name,
        pixel_id: pixelId,
        conversion_token: conversionToken,
        type
      })
      return resStoreData
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async update({ commit }, payload) {
    const { id, name, pixelId, conversionToken, type } = payload
    try {
      const resUpdate = await this.$axios.$put(`/kwai-pixel/${id}/`, {
        name,
        pixel_id: pixelId,
        conversion_token: conversionToken,
        type
      })
      return resUpdate
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async changeTestMode({ commit }, payload) {
    const { id, isTestMode } = payload
    try {
      const resUpdate = await this.$axios.$patch(`/kwai-pixel/${id}/`, {
        is_test_mode: isTestMode
      })
      return resUpdate
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  },

  async delete({ commit }, id) {
    try {
      await this.$axios.$delete(`/kwai-pixel/${id}/`)
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Bad Credentials')
      } else if (error.response?.status === 502) {
        throw new Error('Network Error')
      }
      throw error
    }
  }
}
