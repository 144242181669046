import { render, staticRenderFns } from "./dashboard.vue?vue&type=template&id=89c95d58&scoped=true"
import script from "./dashboard.vue?vue&type=script&lang=js"
export * from "./dashboard.vue?vue&type=script&lang=js"
import style0 from "./dashboard.vue?vue&type=style&index=0&id=89c95d58&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0__ejs@3._54tixbz6jkcfk44fmedkayj5tu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89c95d58",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SclvIcon: require('/app/components/common/SclvIcon.vue').default,MobileHeader: require('/app/components/common/Mobile/Header.vue').default,MobileNavbar: require('/app/components/common/Mobile/Navbar.vue').default,SidebarMain: require('/app/components/Sidebar/Main.vue').default,MobileListBusiness: require('/app/components/common/Mobile/ListBusiness.vue').default,SclvAvatar: require('/app/components/common/SclvAvatar.vue').default,SclvButton: require('/app/components/common/SclvButton.vue').default,Toast: require('/app/components/Toast/index.vue').default,FormBusinessAdd: require('/app/components/Form/BusinessAdd.vue').default,Modal: require('/app/components/Modal/index.vue').default,FormBusinessAccountPhone: require('/app/components/Form/BusinessAccountPhone.vue').default,ModalBusinessInvitation: require('/app/components/Modal/BusinessInvitation.vue').default,ModalPricePlan: require('/app/components/Modal/PricePlan.vue').default,ModalChangePlanConfirm: require('/app/components/Modal/ChangePlanConfirm.vue').default})
